<template>
  <v-app>
    <page-header></page-header>
    <v-main>
      <basic-chord-diagram/>
    </v-main>
    <page-footer></page-footer>
  </v-app>
</template>

<script>
import BasicChordDiagram from '@/components/BasicChordDiagram';
import "@/style/global.scss";
import PageFooter from "@/components/common/PageFooter.vue";
import PageHeader from "@/components/common/PageHeader.vue";

export default {
  name: 'App',

  components: {
    BasicChordDiagram,
    PageHeader,
    PageFooter
  },

  data: () => ({
    //
  }),
  created: function() {
    // var currentUrl = window.location.pathname;
    // console.log("currentURL:" + currentUrl);
  }
};
</script>
