/**
* @Description:    底部模块
* @Author:         FZG 
* @Email:          fangang@gmail.com
* @CreateDate:     2021/04/28 12:00
*/
<template>
    <div class="container">
        <div class="box">
            <div class="rights">
                <img src="../../assets/logo.png" alt="">
            </div>
            <div class="rights">
                <p>© 2021-{{ year }} YunHaiShuZi.com All rights reserved. </p>
                <p>本站内容未经许可，不允许转载</p>
                <p class="check" @click="checkBeian">京ICP备2021013129号-1</p>
            </div>
            <div class="wechat">
                <img src="../../assets/qr_code.jpg" alt="">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PageFooter",
        data() {
            return {
                year: new Date().getFullYear()
            }
        },
        mounted() {

        },
        computed: {},
        methods: {
            checkBeian() {
                // this.$ba.trackEvent('fzg-footer', 'click', '查询备案信息');
                window.open('https://beian.miit.gov.cn');
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/style/common.scss';

    .container {
        max-width: 100%;
        background: black;
        color: #a9a9a9;
        padding: 30px 0;
        font-size: 12px;
        .box {
            @include flexBetween("center");
            max-width: 800px;
            margin: 0 auto;
        }
        img {
            height: 80px;
        }
        .rights {
            p {
                margin: 5px 0;
            }
            .check {
                cursor: pointer;
            }
        }
        .wechat {
            @include flexContent("center", "center");
            flex-direction: column;
            margin-left: 80px;
            p {
                margin-top: 10px;
            }
        }
    }
</style>