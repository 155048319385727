<template>
  <v-container>
    <v-row class="text-center">
      <!--v-col cols="12">
        <v-img
          :src="require('../assets/logo.svg')"
          class="my-3"
          contain
          height="200"
        />
      </v-col-->

      <v-col class="mb-4">
        <h2 class="headline font-weight-bold mb-3">
          下图显示出各个发到局间的发运量关系（从发局到到局）
        </h2>
        <v-row justify="center">
          <ul>
            <li>圆弧上的每段弧线表示该铁路局总的发出的货物量（单位：万吨），以及它占整个发运量的比例（不含各局内的周转）；</li>
            <li>圆弧上的每段弧线连接的连线表示了两个发到局之间发运的货物量；</li>
          </ul>
        </v-row>
        <!-- <h1 class="display-2 font-weight-bold mb-3">
          Welcome to Fangang.com
        </h1> -->
        <v-row justify="center">
          <svg id="chord-diagram" ref="chordDiagram" style="height:900px; width:900px;"></svg>
        </v-row>
        <v-row justify="center">
          <ul>
            <li>上图表明：</li>
            <li>发运量最大的货运站是太原铁路局；它的大部分发运量都发到北京局了；</li>
          </ul>
        </v-row>
      </v-col>

      <v-col
        class="mb-5"
        cols="12"
      >
        <h2 class="headline font-weight-bold mb-3">
          
        </h2>
      </v-col>

      <!--v-col
        class="mb-5"
        cols="12"
      >
        <h2 class="headline font-weight-bold mb-3">
          Ecosystem
        </h2>

        <v-row justify="center">
          <a
            v-for="(eco, i) in ecosystem"
            :key="i"
            :href="eco.href"
            class="subheading mx-3"
            target="_blank"
          >
            {{ eco.text }}
          </a>
        </v-row>
      </v-col-->
    </v-row>
  </v-container>
</template>

<script>
import * as d3 from "d3";
import { allFreightWithoutSelf} from '@/data/freightTransportTrain';

export default {
  name: 'BasicChordDiagram',

  components: {
    // d3
  },
  data() {
    return {
    }
  },
  methods: {
    drawChord () {
    var data = allFreightWithoutSelf;

    var names = data.names === undefined ? d3.range(data.length) : data.names;
    var height=800, width = 800;
    var outerRadius = Math.min(width, height) * 0.5 - 120;
    var innerRadius = outerRadius - 10;

    var colors = data.colors === undefined ? d3.quantize(d3.interpolateRainbow, names.length) : data.colors;


    var tickStep = d3.tickStep(0, d3.sum(data.flat()), 100);

    // var formatValue = d3.format(".1~%");
    var formatValue = d3.format(",.3~f");

    var chord = d3.chord()
        .padAngle(10 / innerRadius)
        .sortSubgroups(d3.descending)
        .sortChords(d3.descending);

    var arc = d3.arc()
        .innerRadius(innerRadius)
        .outerRadius(outerRadius);

    var ribbon = d3.ribbon()
        .radius(innerRadius - 1)
        .padAngle(1 / innerRadius);

    var color = d3.scaleOrdinal(names, colors);

    function ticks({startAngle, endAngle, value}) {
      const k = (endAngle - startAngle) / value;
      return d3.range(0, value, tickStep).map(value => {
        return {value, angle: value * k + startAngle};
      });
    }



    // var svg = d3.create("svg").attr("viewBox", [-width / 2, -height / 2, width, height]);
    var svg = d3.select("#chord-diagram").attr("viewBox", [-width / 2, -height / 2, width, height]);

    var chords = chord(data);

    var group = svg.append("g")
          .attr("font-size", 10)
          .attr("font-family", "sans-serif")
        .selectAll("g")
        .data(chords.groups)
        .join("g");

      group.append("path")
          .attr("fill", d => color(names[d.index]))
          .attr("d", arc);

      group.append("title")
          .text(d => `${names[d.index]} ${formatValue(d.value/10000.0)}万吨`);

    var groupTick = group.append("g")
        .selectAll("g")
        .data(ticks)
        .join("g")
          .attr("transform", d => `rotate(${d.angle * 180 / Math.PI - 90}) translate(${outerRadius},0)`);

      groupTick.append("line")
          .attr("stroke", "currentColor")
          .attr("x2", 6);

      groupTick.append("text")
          .attr("x", 8)
          .attr("dy", "0.35em")
          .attr("transform", d => d.angle > Math.PI ? "rotate(180) translate(-16)" : null)
          .attr("text-anchor", d => d.angle > Math.PI ? "end" : null)
          .text(d => formatValue(d.value/10000.0));

      group.select("text")
          .attr("font-weight", "bold")
          .text(function(d) {
            return this.getAttribute("text-anchor") === "end"
                // ? `↑ ${names[d.index]} ${formatValue(d.value/10000.0)}万吨`
                // : `${names[d.index]} ↓ ${formatValue(d.value/10000.0)}万吨`;
                ? `→ ${names[d.index]} ${formatValue(d.value/10000.0)}万吨`
                : `${names[d.index]} → ${formatValue(d.value/10000.0)}万吨`;
          });

      svg.append("g")
          .attr("fill-opacity", 0.8)
        .selectAll("path")
        .data(chords)
        .join("path")
          .style("mix-blend-mode", "multiply")
          .attr("fill", d => color(names[d.source.index]))
          .attr("d", ribbon)
        .append("title")
          .text(d => `${names[d.source.index]} → ${names[d.target.index]}: ${formatValue(d.source.value)}  ${d.source.index === d.target.index ? "" : `\n${names[d.target.index]} → ${names[d.source.index]}: ${formatValue(d.target.value)}`}`);

      // return svg.node();


    },
  },
  mounted () {
    console.log("ready to call drawChord() ");
    this.drawChord();
  },
}
</script>
<style lang="scss" scoped>
  .container {
    // max-width:100%;
  }
</style>