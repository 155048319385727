let allFreight = [
    [118173448, 61404642, 2381466, 413447, 509648, 2220085, 1260610, 651200, 1788740, 1421806, 290918, 883251, 31834, 5401335, 671990, 236065, 99869, 259813 ],
    [15478523, 308664229, 9325608, 1309947, 1227880, 3431575, 5192227, 728082, 4333420, 4587620, 1771799, 2748098, 88499, 5520114, 1876642, 285934, 364274, 127628 ],
    [753485, 7568543, 231783785, 10172031, 12583937, 9880693, 4335313, 895932, 31088769, 5838296, 1685224, 2811153, 432702, 5237827, 1155208, 2154819, 1386984, 308783 ],
    [615215, 19426173, 72183080, 479872655, 1506657, 15366608, 15296818, 1354330, 67635069, 29897094, 3417222, 6536092, 1639159, 15791490, 1476400, 3274204, 4414355, 954078 ],
    [798479, 7651334, 28643105, 71194454, 79094215, 1514008, 840430, 2133265, 6250448, 1323848, 470450, 329223, 201767, 2658868, 409841, 3912847, 514629, 749994 ],
    [186560, 1614917, 9579891, 1295402, 205140, 36160725, 31444299, 801793, 25034186, 22962200, 7229990, 12188044, 3027884, 10076982, 1864049, 1934110, 3250497, 1704731 ],
    [633587, 1047749, 575456, 172219, 84876, 2839080, 38906271, 2565046, 1057642, 6493420, 3399841, 7602013, 2915270, 9277641, 2021434, 867171, 823144, 234295 ],
    [104035, 287107, 2252707, 10526042, 467113, 13107117, 23148853, 35721557, 19560329, 9941587, 6233866, 10389215, 712838, 44178442, 3268833, 2492333, 1615811, 834813 ],
    [515146, 348557, 13591968, 18230362, 715186, 29401745, 3058983, 8023984, 103888353, 9286853, 3110408, 1303851, 349445, 5946771, 1195564, 4570027, 3625836, 1534701 ],
    [442834, 604985, 695817, 3534453, 299397, 11418098, 11023855, 8217395, 2546251, 109435167, 12571935, 4215014, 811067, 6628863, 1995513, 3583244, 2205954, 845915 ],
    [178490, 70310, 52894, 32004, 116840, 42058, 1125884, 132126, 80910, 4862563, 68636974, 10212232, 589988, 2955026, 918442, 136465, 400750, 28819 ],
    [233983, 129255, 296367, 41526, 29240, 366815, 1993889, 559525, 221712, 1303570, 6009971, 76815539, 3929681, 6145324, 2702862, 243577, 626645, 102781 ],
    [2616, 66691, 42173, 12613, 21312, 432593, 404604, 181827, 84227, 291406, 378515, 12569918, 59761173, 19676338, 24827392, 75609, 127477, 43955 ],
    [537652, 408358, 590730, 405866, 162017, 1268080, 2705900, 1408246, 1039609, 1656058, 1957188, 2515730, 8555470, 75998185, 11851907, 1366519, 2272068, 362609 ],
    [71198, 127340, 488809, 67065, 213225, 275113, 387150, 480988, 616282, 1627973, 451241, 2624064, 10684075, 15777836, 24862869, 633731, 1043427, 101176 ],
    [198196, 1073064, 2092126, 194633, 239014, 3016955, 1867365, 5121449, 1831256, 4925205, 1107736, 1843243, 931219, 14015283, 2270737, 28927356, 1794625, 3886906 ],
    [68204, 243343, 2592693, 425831, 301756, 3137626, 1557841, 1242572, 2489209, 8403131, 1450298, 3302814, 541370, 9269344, 4791543, 19549931, 91960015, 530333 ],
    [164312, 738212, 1199625, 159324, 676098, 1030293, 1858149, 292815, 1459120, 2164654, 672799, 784881, 414875, 2363861, 399168, 1480536, 71433, 18580254 ],
    ];

    let allFreightWithoutSelf = [
        [0, 61404642, 2381466, 413447, 509648, 2220085, 1260610, 651200, 1788740, 1421806, 290918, 883251, 31834, 5401335, 671990, 236065, 99869, 259813 ],
        [15478523, 0, 9325608, 1309947, 1227880, 3431575, 5192227, 728082, 4333420, 4587620, 1771799, 2748098, 88499, 5520114, 1876642, 285934, 364274, 127628 ],
        [753485, 7568543, 0, 10172031, 12583937, 9880693, 4335313, 895932, 31088769, 5838296, 1685224, 2811153, 432702, 5237827, 1155208, 2154819, 1386984, 308783 ],
        [615215, 19426173, 72183080, 0, 1506657, 15366608, 15296818, 1354330, 67635069, 29897094, 3417222, 6536092, 1639159, 15791490, 1476400, 3274204, 4414355, 954078 ],
        [798479, 7651334, 28643105, 71194454, 0, 1514008, 840430, 2133265, 6250448, 1323848, 470450, 329223, 201767, 2658868, 409841, 3912847, 514629, 749994 ],
        [186560, 1614917, 9579891, 1295402, 205140, 0, 31444299, 801793, 25034186, 22962200, 7229990, 12188044, 3027884, 10076982, 1864049, 1934110, 3250497, 1704731 ],
        [633587, 1047749, 575456, 172219, 84876, 2839080, 0, 2565046, 1057642, 6493420, 3399841, 7602013, 2915270, 9277641, 2021434, 867171, 823144, 234295 ],
        [104035, 287107, 2252707, 10526042, 467113, 13107117, 23148853, 0, 19560329, 9941587, 6233866, 10389215, 712838, 44178442, 3268833, 2492333, 1615811, 834813 ],
        [515146, 348557, 13591968, 18230362, 715186, 29401745, 3058983, 8023984, 0, 9286853, 3110408, 1303851, 349445, 5946771, 1195564, 4570027, 3625836, 1534701 ],
        [442834, 604985, 695817, 3534453, 299397, 11418098, 11023855, 8217395, 2546251, 0, 12571935, 4215014, 811067, 6628863, 1995513, 3583244, 2205954, 845915 ],
        [178490, 70310, 52894, 32004, 116840, 42058, 1125884, 132126, 80910, 4862563, 0, 10212232, 589988, 2955026, 918442, 136465, 400750, 28819 ],
        [233983, 129255, 296367, 41526, 29240, 366815, 1993889, 559525, 221712, 1303570, 6009971, 0, 3929681, 6145324, 2702862, 243577, 626645, 102781 ],
        [2616, 66691, 42173, 12613, 21312, 432593, 404604, 181827, 84227, 291406, 378515, 12569918, 0, 19676338, 24827392, 75609, 127477, 43955 ],
        [537652, 408358, 590730, 405866, 162017, 1268080, 2705900, 1408246, 1039609, 1656058, 1957188, 2515730, 8555470, 0, 11851907, 1366519, 2272068, 362609 ],
        [71198, 127340, 488809, 67065, 213225, 275113, 387150, 480988, 616282, 1627973, 451241, 2624064, 10684075, 15777836, 0, 633731, 1043427, 101176 ],
        [198196, 1073064, 2092126, 194633, 239014, 3016955, 1867365, 5121449, 1831256, 4925205, 1107736, 1843243, 931219, 14015283, 2270737, 0, 1794625, 3886906 ],
        [68204, 243343, 2592693, 425831, 301756, 3137626, 1557841, 1242572, 2489209, 8403131, 1450298, 3302814, 541370, 9269344, 4791543, 19549931, 0, 530333 ],
        [164312, 738212, 1199625, 159324, 676098, 1030293, 1858149, 292815, 1459120, 2164654, 672799, 784881, 414875, 2363861, 399168, 1480536, 71433, 0 ],
        ];
    
allFreight = Object.assign(allFreight, {								
    names: ["哈", "沈", "京", "太", "呼", "郑", "武", "西", "济", 
            "上", "南", "广", "宁", "成", "昆", "兰", "乌", "青"],
    colors: ["#DC143C", "#69b40f", "#008000", "#c8125c", "#008fc8", "#10218b", "#134b24", "#7CFC00", "#303030", "#030303",
            "#4c4c4c", "#567890", "#678900", "#80391e", "#890000", "#ebdab4", "#506789", "#edb06b", "#1979a9", "#067890"]
});

allFreightWithoutSelf = Object.assign(allFreightWithoutSelf, {								
    names: ["哈", "沈", "京", "太", "呼", "郑", "武", "西", "济", 
            "上", "南", "广", "宁", "成", "昆", "兰", "乌", "青"],
    colors: ["#DC143C", "#69b40f", "#008000", "#FF4500", "#008fc8", "#10218b", "#6A5ACD", "#7CFC00", "#303030", 
            "#FFD700", "#FF00FF", "#678900", "#8B008B", "#890000", "#ebdab4", "#506789", "#edb06b", "#FA8072"]
});
      
export {
    allFreight, allFreightWithoutSelf
}