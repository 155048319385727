/**
* @Description:    顶部导航栏
* @Author:         FZG 
* @Email:          fangang@gmail.com
* @CreateDate:     2021/04/28 12:00
*/
<template>
    <!-- <v-app-bar
      app
      color="primary"
      dark
      elevate-on-scroll 
      :dense="isShowScroll"
    >
     <div class="d-flex align-center">
        <v-img
          alt="yunhaishuzi.com Logo"
          class="shrink mr-2"
          contain
          src="../../assets/logo.png"
          transition="scale-transition"
          :width="responsiveHeaderLogWidth"
        />
      <v-toolbar-title>Welcome to YunHaiShuZi.com</v-toolbar-title>

      </div>

      <v-spacer></v-spacer>

      <v-btn icon>
        <v-icon large>mdi-arrow-up-bold-box-outline</v-icon>
      </v-btn>

      <v-btn icon>
        <v-icon large>mdi-dialpad</v-icon>
      </v-btn>

    </v-app-bar> -->

    <div class="header" :class="{'scroll-down': isShowScroll || isDetail}">
        <img src="@/assets/logo.png" alt="" class="logo" @click="home">
        <div class="menu">
            <ul>
                <li class="menu-item" v-for="(item, index) in menu" :key="index" @click="intoModel(item.model)">{{ item.name }}</li>
            </ul>
        </div>
    </div>
</template>

<script>

    import { throttle } from "@/utils/util";

    export default {
        name: "PageHeader",
        props: {
            isDetail: {//是否为详情页
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                headerLogoWidth: 60,
                menu: [
                    {name: '首页', model: 'home'},
                    // {name: '文集', model: 'article'},
                    // {name: '收藏', model: 'collect'},
                    // {name: '娱乐', model: 'amusement'},
                    {name: '关于', model: 'about'}
                ],//菜单
                isShowScroll: false,//是否绑定scroll class
            }
        },
        mounted() {
            window.addEventListener('scroll',throttle(this.handleScroll, 200), true);
        },
        computed: {
            responsiveHeaderLogWidth() {
                return this.isShowScroll? 40:60;
            }
        },
        methods: {
            handleScroll() {
                let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
                if (scrollTop > 32) {
                    this.isShowScroll = true;
                } else {
                    this.isShowScroll = false;
                }
            },
            home() {
                // this.$ba.trackEvent('hxkj-header', 'nav', 'home');
                this.$router.push({name: 'home'});
            },
            intoModel(model) {
                this.$ba.trackEvent('hxkj-header', 'nav', model);
                this.$router.push({name: model});
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "@/style/common.scss";

    .header {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        padding: 15px 15px;
        @include flexBetween("center");
        z-index: 99;
        font-size: 14px;
        background-color:#1976d2 !important;
        img {
            height: 50px;
            cursor: pointer;
            margin-left: 20px;
        }
        .menu ul {
            @include flexAlign("center");
            color: white;
            list-style-type: none;
            .menu-item {
                margin: 5px;
                padding: 5px 20px;
                cursor: pointer;
                border: 1px solid transparent;
                font-family: 'pingfang';
                font-size:18px;
                font-weight: 900;
                background-color: rgba(0, 0, 0, 0.1);
                border: 1px solid rgba(221, 221, 221, 0.5);
                border-radius:5px;
                color: #dddddd;
                &:hover {
                    // border: 1px solid $main-color;
                    border: 1px solid white;
                    // border-radius: 5px;
                    color: white;
                }
            }
        }
    }

    .scroll-down {
        background: #000;
        padding: 5px 10px;
        box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%);
        img {
            height: 40px;
            cursor: pointer;
            margin-left: 0px;
        }
        .menu ul {
            .menu-item {
                font-size:16px;
                margin: 0 5px;
                &:hover {
                    border: 1px solid white;
                }
            }
        }
    }

</style>